.human-body {
    position: relative;
    display: block;
    margin: 40px auto;
}
.not-ninja {
    display: block;
}
.ninja {
    display: none;
}
.human-body svg {
    margin: 0 auto;
}
.human-body .svg-body,
.human-body .svg-bodyselected {
    fill-opacity: 0;
}
.human-body .svg-body:hover {
    cursor: pointer;
    fill: red;
    fill-opacity: 0.2;
}

@keyframes pulse {
    from {
        stroke-opacity: 1;
        stroke-width: 5px;
    }
    to {
        stroke-opacity: 0.5;
        stroke-width: 2px;
    }
}

.svg-sub-body {
    fill: none;
    stroke: red;
    stroke-width: 1px;
    display: none;
    transition: stroke-width 0.8s, transform 0.8s;
}

.svg-sub-body:hover {
    cursor: pointer;
    fill: red;
}
.svg-sub-body.highlight {
    fill: red;
    animation-duration: 1.5s;
    animation-name: pulse;
    animation-iteration-count: infinite;
}

.visible {
    display: block !important;
}
.overflow-hiden {
    overflow: hidden;
}
.human-body {
    transition: top 1s, left 1s, right 1s, bottom 1s, transform .5s;
    top: 0;
}
.human-body-wrap {
    overflow: hidden;
    padding-top: 24px;
    padding-bottom: 24px;
    min-height: 600px;
}

/* middle */
.human-body.front.middle {
    transform: scale(2);
    top: 20%;
    left: 0%;
}
.human-body.back.middle {
    transform: scale(2);
    top: 20%;
    left: 0%;
}
.human-body.side.middle {
    transform: scale(2);
    top: 20%;
    left: 0;
}
.human-body.sideleft.middle {
    transform: scale(2);
    top: 20%;
    left: 0;
}

/* Right */
.human-body.front.right {
    transform: scale(2);
    top: 20%;
    left: 35%;
}
.human-body.back.right {
    transform: scale(2);
    top: 20%;
    left: -35%;
}
.human-body.side.right {
    transform: scale(2);
    top: 20%;
    left: 0;
}
.human-body.sideleft.right {
    transform: scale(2);
    top: 20%;
    left: 0;
}

/* Left */
.human-body.front.left {
    transform: scale(2);
    top: 20%;
    left: -35%;
}
.human-body.back.left {
    transform: scale(2);
    top: 20%;
    left: 35%;
}
.human-body.side.left {
    transform: scale(2);
    top: 20%;
    left: 0;
}
.human-body.sideleft.left {
    transform: scale(2);
    top: 20%;
    left: 0;
}

/* Bottom Right */
.human-body.front.rightbottom {
    transform: scale(2);
    top: -350px;
    left: 35%;
}
.human-body.back.rightbottom {
    transform: scale(2);
    top: -350px;
    left: -35%;
}
.human-body.side.rightbottom {
    transform: scale(2);
    top: -350px;
    left: 0;
}
.human-body.sideleft.rightbottom {
    transform: scale(2);
    top: -350px;
    left: 0;
}

/* Bottom Left */
.human-body.front.leftbottom {
    transform: scale(2);
    top: -350px;
    left: -35%;
}
.human-body.back.leftbottom {
    transform: scale(2);
    top: -350px;
    left: 35%;
}
.human-body.side.leftbottom {
    transform: scale(2);
    top: -350px;
    left: 0;
}
.human-body.sideleft.leftbottom {
    transform: scale(2);
    top: -350px;
    left: 0;
}
